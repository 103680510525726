// League management (1 Aug to 30 Sep))
export const checkIfIsNextSeasonManagement = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  // Admin can start adding leagues for the next year by 1 August of the current year
  // TODO: EC-3565 revert to:
  const startDateNextSeasonManagement = new Date(currentYear, 7, 1)
    .toISOString()
    .replace('Z', '');
  // Admin can edit leagues until 30 September of the current year - after 30 September, leagues are
  // TODO: EC-3565 revert to: const endDateNextSeasonManagement = new Date(currentYear, 8, 30)
  const endDateNextSeasonManagement = new Date(
    currentYear === 2024 ? currentYear + 1 : currentYear,
    1,
    31
  )
    .toISOString()
    .replace('Z', '');

  return true;

  // return (
  //   today >= new Date(startDateNextSeasonManagement) &&
  //   today <= new Date(endDateNextSeasonManagement)
  // );
};

import { useQuery } from '@apollo/client';
import { settingGrafanaReport } from '@ecdlink/graphql';
import { useEffect, useState } from 'react';
import { useUser } from '../../../../hooks/useUser';
import { Button } from '@ecdlink/ui';

export default function GeneralDashboard() {
  const { user } = useUser();
  const [generalURL, setGeneratlURL] = useState('');
  const { data } = useQuery(settingGrafanaReport, {
    fetchPolicy: 'cache-and-network',
  });

  // const [iframeUrl, setIframeUrl] = useState('');
  // const apiKey = 'glsa_JpBcXG6ybYTSjQUWLMefUwDmqFzd4J4o_bf561e9a';

  useEffect(() => {
    if (data) {
      var url =
        data.settings.Grafana.GeneralDashboard +
        '&from=now-6M&to=now&orgId=1&kiosk&var-UserId=' +
        user?.id;
      setGeneratlURL(url);
      // const urlWithAuth = `${url}?apiKey=${apiKey}`;
      // setIframeUrl(urlWithAuth);
    }
  }, [data, user?.id]);

  const goToDashboard = () => {
    window.open('https://dashboard.ecdconnect.co.za/login', '_blank').focus();
  };

  return (
    <div className="mt-20 h-full">
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <p className="mt-1 text-sm text-gray-500">
            As an interim solution, the dashboard link can be found by clicking
            the button below
          </p>
          <div className="mt-6">
            <button
              onClick={goToDashboard}
              type="button"
              className="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              See dashboard
            </button>
          </div>
        </div>
      </div>
    </div>

    //         {/* <iframe
    //           title="General Dashboard"
    //           className="dashboard-container w-full"
    //           src={iframeUrl}
    //           width={`100%`}
    //           height={`100%`}
    //         ></iframe> */}
    //       </div>
    //     </div>
  );
}
